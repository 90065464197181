<template>
  <p-modal
    size="md"
    v-if="value"
    :value="value"
    @input="$emit('input', $event)"
    :title="$lang.videoStoryApprovalModal_Title"
    @save="onSave"
    @cancel="$emit('input', false)">
    <video :src="videoStory.video.mp4Url" controls class="video"></video>

    <v-radio-group v-model="approved">
      <v-radio :label="$lang.videoStoryApprovalModal_Approve" :value="true" />
      <v-radio :label="$lang.videoStoryApprovalModal_Reject" :value="false" />
    </v-radio-group>
  </p-modal>
</template>

<script>
  import { mapGetters } from "vuex";
  import api from "@/services/api/index";

  export default {
    props: {
      value: Boolean,
      videoStory: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        approved: true,
      };
    },
    computed: {
      ...mapGetters({ currentUser: "getCurrentUser" }),
    },
    methods: {
      async onSave() {
        this.$emit("input", false);
        let response;

        if (this.approved) {
          response = await api.dashboard.updateVideoStory({
            userVideoStoryID: this.videoStory._id,
            approvedAt: new Date(),
            approvedAdminID: this.currentUser._id,
          });
        } else {
          response = await api.dashboard.deleteVideoStory({
            userVideoStoryID: this.videoStory._id,
          });
        }
        if (response?.status === 200) {
          this.$toast.success(this.$lang.editProfile_Success);
          this.$emit("refresh");
        } else {
          return this.$toast.error(this.$lang.general_ToastError);
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .video {
    width: 100%;
    border-radius: 8px;
  }
</style>
